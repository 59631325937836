import moment from 'moment';
import store from '@/lib/store';

export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    __btn_test_click() {
      const __this = this;

      // 两种写法都可以
      // __this.$store.commit(`update_last_refresh_time`, moment().format(`YYYY-MM-DD HH:mm:ss`));
      store.commit(`update_last_refresh_time`, moment().format(`YYYY-MM-DD HH:mm:ss`));
    },
  },
  created() {
    // const __this = this;
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
}
